import { getMemoizedFinalClassnames } from '../../../hooks/useFinalClassnames';
export const formGroupDefaultClassNames = /* tw */ {
    group: 'form-group relative mb-8',
    group__custom: '',
    group__error: 'form-group--error',
    label: 'form-group__label inline-block font-medium text-xs mb-2 text-black',
    label__custom: '',
    label__disabled: 'text-gray-30',
    label__error: 'text-error',
    label__hidden: '!hidden',
    error: 'form-group__error mt-2 text-xs text-red text-left',
    errorIco: 'hidden',
    errorField: 'inline-block font-semibold',
    label_requiredPostfix: 'form-group__label-postfix form-group__label-postfix--required inline-block ml-1 text-gray-70 text-xs',
    label_optionalPostfix: 'form-group__label-postfix form-group__label-postfix--optional inline-block ml-2 text-gray-70 text-xs',
    sm: {
        label: 'text-xs',
    },
    md: {
        label: 'text-sm',
    },
};
export function formGroupClassNames(...args) {
    return getMemoizedFinalClassnames('formGroupClassNames', formGroupDefaultClassNames, ...args);
}

'use client';
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { FormGroupOptionalPostfix } from '@edeeone/juan-core/components/form/formGroup/FormGroupOptionalPostfix';
import { FormGroupRequiredPostfix } from '@edeeone/juan-core/components/form/formGroup/FormGroupRequiredPostfix';
import { useIsRequired } from '@edeeone/juan-core/components/form/formGroup/isRequired';
import { twMergeJuan } from '@edeeone/juan-core/hooks/useFinalClassnames';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import get from 'lodash/get';
import { Children, cloneElement, isValidElement, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import { formGroupClassNames } from './formGroup.styles';
import { SvgSpriteIcon } from '@edeeone/juan-core/components/svgSpriteIcon/SvgSpriteIcon';
export const FormGroup = ({ id, name, label, visibleLabel, styles, design, size = 'sm', children, disabled = false, showLabel = true, showRequiredLabelPostfix = false, showOptionalLabelPostfix = true, forcedErrorMessage, onFocus, onBlur, i18nKey, hideValidationMessage = false, }) => {
    const { formState: { errors }, } = useFormContext();
    const isRequired = useIsRequired(name);
    const finalStyles = formGroupClassNames(styles, design, size);
    const uid = useId();
    const _id = id || uid;
    const childProps = {
        id: _id,
        name,
    };
    const i18nNamespaceFinal = `Form${i18nKey ? `.${i18nKey}` : ''}`;
    const { t, tFormat } = useI18n(i18nNamespaceFinal);
    const finalLabel = label || t(name);
    const error = get(errors, name);
    return (_jsxs("div", { className: twMergeJuan(finalStyles.group, finalStyles.group__custom, (forcedErrorMessage || error) && finalStyles.group__error), onFocus: onFocus, onBlur: onBlur, children: [showLabel && (_jsxs(_Fragment, { children: [visibleLabel && (_jsx("p", { className: twMergeJuan(finalStyles.label, (forcedErrorMessage || error) && finalStyles.label__error, disabled && finalStyles.label__disabled), children: visibleLabel })), _jsxs("label", { htmlFor: _id, className: twMergeJuan(finalStyles.label, finalStyles.label__custom, (forcedErrorMessage || error) && finalStyles.label__error, disabled && finalStyles.label__disabled, visibleLabel && finalStyles.label__hidden), children: [finalLabel, isRequired && showRequiredLabelPostfix && (_jsx(FormGroupRequiredPostfix, { className: finalStyles.label_requiredPostfix, i18nNamespace: i18nNamespaceFinal })), !isRequired && showOptionalLabelPostfix && (_jsx(FormGroupOptionalPostfix, { className: finalStyles.label_optionalPostfix, i18nNamespace: i18nNamespaceFinal }))] })] })), Children.map(children, (child, index) => {
                if (isValidElement(child)) {
                    return cloneElement(child, {
                        ...childProps,
                        ...(child.props || {}),
                        key: index,
                        hasError: !!forcedErrorMessage || typeof error === 'object',
                    });
                }
                else {
                    return null;
                }
            }), !hideValidationMessage && (forcedErrorMessage || error?.message) && (_jsxs("p", { className: finalStyles.error, children: [_jsx(SvgSpriteIcon, { className: finalStyles.errorIco, icon: "warning", ariaHidden: true }), forcedErrorMessage ||
                        t(`validationErrors.${error?.type}`, {
                            /*@ts-ignore*/
                            ...(error?.values || {}),
                            field: (_jsx("strong", { className: finalStyles.errorField, children: finalLabel }, finalLabel)),
                        })] }))] }));
};

import { useValidationSchemaClient } from '@edeeone/juan-core/components/form/Form';
import { useFormContext } from '@edeeone/juan-core/hooks/useForm';
import get from 'lodash/get';
import { useMemo } from 'react';
export const isRequired = (name, schema, values) => {
    const splitPath = `.${name}`.split('.');
    // lines.0.description should lead to properties.lines.items.properties.description
    const splitPathWithItems = splitPath
        .map((pathPart, index) => {
        const pathPartAsNumber = Number(pathPart);
        if (pathPart !== '' && !isNaN(pathPartAsNumber)) {
            return pathPartAsNumber;
        }
        if (splitPath[index + 1] === undefined ||
            isNaN(Number(splitPath[index + 1]))) {
            return pathPart;
        }
        return `${pathPart}.items`;
    })
        .filter((it) => {
        return typeof it !== 'number';
    });
    const schemaPath = splitPathWithItems.join('.properties.').replace(/^\./, '');
    const propertyOptions = get(schema, schemaPath);
    let isRequired = !!propertyOptions?.isRequired;
    const checkAllOf = (allOf, schemaPath) => {
        const allOfThenPropertyOptions = get(allOf.then, schemaPath);
        const allOfElsePropertyOptions = get(allOf.else, schemaPath);
        if (allOfThenPropertyOptions || allOfElsePropertyOptions) {
            // check if all properties in if are same like in values
            const ifProperties = get(allOf, 'if.properties', {});
            const ifConditionsMet = Object.keys(ifProperties).every((key) => {
                return ifProperties[key].const === values[key];
            });
            if (ifConditionsMet && allOfThenPropertyOptions?.isRequired) {
                isRequired = true;
            }
            else if (!ifConditionsMet && allOfElsePropertyOptions?.isRequired) {
                isRequired = true;
            }
        }
        // Check for nested if-then-else in then and else
        if (allOf.then) {
            checkAllOf(allOf.then, schemaPath);
        }
        if (allOf.else) {
            checkAllOf(allOf.else, schemaPath);
        }
    };
    if (schema?.allOf) {
        schema.allOf?.forEach((allOf) => {
            checkAllOf(allOf, schemaPath);
        });
    }
    return isRequired;
};
export const useIsRequired = (name) => {
    const { getValues } = useFormContext();
    const schema = useValidationSchemaClient();
    const values = getValues();
    const memoizedIsRequired = useMemo(() => {
        return isRequired(name, schema, values);
    }, [name, schema, values]);
    return memoizedIsRequired;
};

'use client';
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { ApolloError } from '@apollo/client';
import { formClassNames } from '@edeeone/juan-core/components/form/Form.styles';
import { useServerActionModal } from '@edeeone/juan-core/components/serverActionModal/useServerActionModal';
import { useI18n } from '@edeeone/juan-core/i18n/useI18n';
import { useRouter } from 'next/navigation';
import { createContext, isValidElement, useCallback, useContext, useState, } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
export const FormStatusContext = createContext({
    successStatus: { success: false, message: '' },
    isLoading: false,
});
export const useFormStatus = () => {
    return useContext(FormStatusContext);
};
export const ValidationSchemaClientContext = createContext(undefined);
export const useValidationSchemaClient = () => {
    return useContext(ValidationSchemaClientContext);
};
export const Form = ({ styles, id, novalidate = true, schema, i18nNamespace, closeModalOnSuccess = false, refreshPageOnSuccess = false, onSubmit, action, children, ...useFormProps }) => {
    const finalStyles = formClassNames(styles);
    const i18nNamespaceFinal = `Form${i18nNamespace ? `.${i18nNamespace}` : ''}`;
    const { t } = useI18n(i18nNamespaceFinal);
    const { closeModalWithDelay } = useServerActionModal();
    const { refresh: refreshPage } = useRouter();
    const [successStatus, setSuccessStatus] = useState({
        success: false,
        message: '',
    });
    const [isLoadingStatus, setIsLoadingStatus] = useState(false);
    const formMethods = useForm({
        mode: 'onTouched',
        ...useFormProps,
        resolver: async (data, context, options) => {
            return getAjvSchemaAndValidate(data, context, options, schema, t);
        },
    });
    /**
     * Logs an error message to the console
     * when there are properties in the default values
     * that are not defined validation schema.
     *
     * This helps developers identify mismatches
     * between the validation schema and the default values.
     * @param errors - The field errors object containing validation errors.
     */
    const logUndefinedPropsInValidationSchema = useCallback((errors) => {
        const undefinedPropsInValidationSchema = Object.values(errors).filter((errorValue) => {
            return (errorValue?.message === 'must NOT have additional properties' &&
                errorValue.type === 'additionalProperties');
        });
        if (undefinedPropsInValidationSchema.length > 0) {
            console.error('All properties must be defined in validation schema. Missing properties with name: ', undefinedPropsInValidationSchema);
        }
    }, []);
    const handleSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const hasSubmitHandler = !!onSubmit || !!action;
        const submitHandler = onSubmit || handleAction;
        if (!hasSubmitHandler) {
            return;
        }
        try {
            const result = await formMethods.handleSubmit(submitHandler)(e);
            logUndefinedPropsInValidationSchema(formMethods.formState.errors);
            if (refreshPageOnSuccess) {
                refreshPage();
            }
            if (closeModalOnSuccess) {
                closeModalWithDelay();
            }
            return result;
        }
        catch (error) {
            if (error instanceof Error || error instanceof ApolloError) {
                formMethods.setError('root', { message: error.message });
            }
        }
        return;
    };
    /**
     * Handles error messages, logging and extracting text if a JSX element is returned.
     * Used as a fallback when a translation with chunks is returned instead of a plain string.
     * The form expects only plain error messages.
     * @param actionResult - The action result object containing the error message.
     * @returns The error message as a string.
     */
    const handleActionResultErrorMessage = useCallback(({ message }) => {
        if (isValidElement(message)) {
            console.error('Error message is a JSX element:', message);
            return message.props.children || 'An error occurred';
        }
        return message;
    }, []);
    const handleAction = async () => {
        if (!action) {
            return;
        }
        try {
            setIsLoadingStatus(true);
            formMethods.clearErrors('root');
            const actionResult = await action({
                ...formMethods.getValues(),
                formId: id,
            });
            if (actionResult) {
                if (actionResult.success) {
                    setSuccessStatus(actionResult);
                }
                else {
                    formMethods.setError('root', {
                        message: handleActionResultErrorMessage(actionResult),
                    });
                }
            }
        }
        catch (error) {
            if (error instanceof Error || error instanceof ApolloError) {
                formMethods.setError('root', { message: error.message });
            }
        }
        finally {
            setIsLoadingStatus(false);
        }
        return;
    };
    return (_jsx(ValidationSchemaClientContext.Provider, { value: schema, children: _jsx(FormStatusContext.Provider, { value: { successStatus, isLoading: isLoadingStatus }, children: _jsx(FormProvider, { ...formMethods, children: _jsxs("form", { method: "POST", className: finalStyles.form, onSubmit: handleSubmit, noValidate: novalidate, children: [typeof children === 'function' && children(formMethods), typeof children !== 'function' && children] }) }) }) }));
};
export async function getAjvSchemaAndValidate(data, context, options, schema, t) {
    const promises = [
        import(
        /* webpackChunkName: "ajvReactHookFormResolver" */
        '../../ajv/ajvReactHookFormResolver'),
        import(/* webpackChunkName: "ajv" */ '../../ajv/ajv'),
    ];
    const allResults = await Promise.all(promises);
    const [{ ajvResolver }, { getAjv }, gqlSchemaRes] = allResults;
    const { data: gqlSchemaData } = gqlSchemaRes || {};
    let gqlSchema;
    if (gqlSchemaData) {
        const firstKey = Object.keys(gqlSchemaData || {})[0];
        gqlSchema = firstKey ? gqlSchemaData[firstKey] : {};
    }
    return ajvResolver(gqlSchema || schema, getAjv({ t }), t)(data, context, options);
}
